<template>
  <div class="activate-site">
    <!-- Header Content -->
    <div class="header-site">
      <router-link to="/">
        <b-button style="display: flex; background-color: white; color: black"
          >Back</b-button
        >
      </router-link>
      Copy Category From One Collection To Another
    </div>
    <div class="create-center">
      <b-form-text class="instructions">
        If you click on a category on a site, the category ID is the string in
        the URL after the last slash e.g. in bold is the category ID of this URL
        https://site.rocketalumnisolutions.com/subcategories/45/<strong
          >6466512fb8488257c25de574</strong
        >
      </b-form-text>
      <div class="source-collection">
        <!-- Source Collection Dropdown -->
        <MultiSelect
          class="dropdown"
          v-if="(clientCollectionIDs || []).length"
          v-model="sourceCollectionID"
          :options="clientCollectionIDs"
          :multiple="false"
          placeholder="Select Source Collection"
          label="name"
          track-by="id"
        ></MultiSelect>
        <!-- Category ID Input -->
        <b-form-input
          v-model="categoryIdToCopy"
          type="text"
          placeholder="Category ID to Copy"
          required
          class="my-3 input"
        ></b-form-input>
      </div>
      <!-- Destination Collection Dropdown -->
      <MultiSelect
        class="dropdown"
        v-if="(clientCollectionIDs || []).length"
        v-model="destinationCollectionID"
        :options="clientCollectionIDs"
        :multiple="false"
        placeholder="Select Destination Collection"
        label="name"
        track-by="id"
      ></MultiSelect>
      <!-- Submit Button -->
      <b-button
        class="btn-primary"
        style="width: 200px"
        type="submit"
        :disabled="disableSubmitButton"
        @click="copyCategory()"
        >Copy Category</b-button
      >
    </div>
  </div>
</template>

<script>
import MultiSelect from "vue-multiselect";

export default {
  components: {
    MultiSelect,
  },
  data() {
    return {
      sourceCollectionID: "",
      destinationCollectionID: "",
      categoryIdToCopy: "",
    };
  },
  computed: {
    clientCollectionIDs() {
      return this.$store.getters.getClientCollectionIDs;
    },
    disableSubmitButton() {
      return (
        this.sourceCollectionID === "" ||
        this.destinationCollectionID === "" ||
        this.categoryIdToCopy === ""
      );
    },
  },
  methods: {
    copyCategory() {
      this.$api
        .post(
          `/copyCategory/${this.sourceCollectionID.id}/${this.categoryIdToCopy}`,
          {
            destinationCollectionID: this.destinationCollectionID.id,
          }
        )
        .then(() => {
          alert("Category Copied Successfully");
        })
        .catch((error) => {
          alert("Error Copying Category: " + error.response.data.error);
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

.header-site {
  padding: 30px;
  font-size: 30px;
  background-color: #133353;
  color: white;
}

.create-center {
  margin-top: 20px;
  padding-inline: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.source-collection {
  display: flex;
  gap: 20px;
  align-items: center;
}

.dropdown,
.input {
  width: 300px;
}

.btn-primary {
  align-self: center;
  margin-top: 20px;
}

.instructions {
  text-align: left;
  font-size: 14px;
}
</style>
